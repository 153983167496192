type TFeatureCardContent = {
  id: string;
  icon: string;
  title: string;
  body: string;
  availableIn?: Array<string>;
  link?: string;
  addOn?: boolean;
};

type TFeaturesCopy = {
  allClinics: Array<TFeatureCardContent>;
};

type TFeaturesData = {
  "en-GB": TFeaturesCopy;
  "en-US": TFeaturesCopy;
  "en-IE": TFeaturesCopy;
};

const allClinics: Array<TFeatureCardContent> = [
  {
    id: "2-way-text",
    icon: "/icons/features/chat_bubble.svg",
    title: "Unlimited 2-way SMS and app messages",
    body: "Clients contact you how they want — via SMS, your website or the app.",
  },
  {
    id: "digital-payments",
    icon: "/icons/features/attach_money_gbp.svg",
    title: "Digital payments",
    body: "Take payments faster and more securely. Just 1.4% + 20p per transaction.",
    link: "/benefits/payments",
  },
  {
    id: "appointment-booking",
    icon: "/icons/features/event.svg",
    title: "Appointment requests or booking",
    body: "Approve or decline requests in your dashboard. Bookings sync with your PMS.",
    addOn: true,
  },
  {
    id: "campaigns",
    icon: "/icons/features/settings_input_antenna.svg",
    title: "Campaigns",
    body: "Contact all pets, or particular audiences, with newsletters, promos or seasonal info. In just a few minutes.",
  },
  {
    id: "reminders",
    icon: "/icons/features/notifications_active.svg",
    title: "Reminders",
    body: "Keep clients compliant with medication, and aware of appointments. Sent as push notifications, emails and via SMS.",
    addOn: true,
  },
  {
    id: "wellness-plans",
    icon: "/icons/features/favorite.svg",
    title: "Health and wellness plans",
    body: "Clients can sign up digitally in minutes. Manage your subscriptions all in one place! Just 1.4% + 20p per subscription.",
    addOn: true,
    link: "benefits/petsapp-wellness-plans",
    availableIn: ["en-GB"],
  },
];

export const data: TFeaturesData = {
  "en-GB": {
    allClinics,
  },
  "en-US": {
    allClinics: [
      ...allClinics.map((data) => {
        if (data.id === "digital-payments") {
          return {
            ...data,
            icon: "/icons/features/attach_money.svg",
            body: "Take payments faster and more securely. Just 2.9% + 30c per transaction.",
          };
        }

        return data;
      }),
    ],
  },
  "en-IE": {
    allClinics: [
      ...allClinics.map((data) => {
        if (data.id === "digital-payments") {
          return {
            ...data,
            icon: "/icons/features/attach_money_eur.svg",
            body: "Take payments faster and more securely. Just 1.4% + 25c per transaction.",
          };
        }

        return data;
      }),
    ],
  },
};
