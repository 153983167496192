import React from "react";
//libs
import NextImage from "next/image";
import { useRouter } from "next/router";
// components
import {
  Section,
  SliderContainer,
  TitleContainer,
  Card,
  IconBlock,
  MainHeader,
  IllustrationWrapper,
} from "./Features.styles";
// theme
import { Body, Headline, LargeTitle, Title } from "src/theme/typography";
import { colors } from "src/theme";
import { data } from "./data";
import { FlexRow, Pill } from "src/components/ui/common";
import { amplitudeEvent, formatEventName } from "src/libs/amplitude";
import Slider from "react-slick";

type TDataLocale = "en-US" | "en-GB" | "en-IE";

const settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true,
  dots: false,
  infinite: true,
  pauseOnHover: true,
  slidesToScroll: 1,
  slidesToShow: 3,
  speed: 1000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Features = () => {
  const { pathname, locale } = useRouter();

  const dataLocale: TDataLocale = ["en-US", "en-CA", "fr-CA"].includes(locale || "")
    ? "en-US"
    : (locale as TDataLocale);

  return (
    <Section>
      <IllustrationWrapper>
        <NextImage src="/illustrations/cat.png" height={260} width={260} />
      </IllustrationWrapper>
      <MainHeader>
        <TitleContainer>
          <Title textColor={colors.white}>
            How we&apos;re <s>different</s> better&hellip;
          </Title>
          <LargeTitle textColor={colors.white}>
            PetsApp gives you all the tools you need. All in one place.
          </LargeTitle>
          <Body textColor={colors.mainLight}>
            Save time, grow your business, and delight customers.
          </Body>
        </TitleContainer>
      </MainHeader>
      <SliderContainer>
        <Slider {...settings}>
          {data[dataLocale].allClinics.map(
            ({ id, icon, title, body, availableIn, link, addOn }, index) => {
              if (availableIn && !availableIn.includes(locale || "")) {
                return;
              }

              return (
                <Card
                  key={id}
                  as={link ? "a" : undefined}
                  href={link ? String(link) : undefined}
                  gap="4px"
                  onClick={() => amplitudeEvent(formatEventName(pathname, "features", id))}
                >
                  <FlexRow gap="12px" style={{ marginBottom: 8 }}>
                    <IconBlock>
                      <NextImage src={icon} width={32} height={32} alt="" />
                    </IconBlock>
                    {addOn && <Pill>Add on</Pill>}
                    {link && (
                      <FlexRow gap="6px" style={{ placeContent: "flex-end" }}>
                        <Headline id="read-more" textColor={colors.grey}>
                          More
                        </Headline>
                        <NextImage
                          src={"/icons/arrow-right-black.svg"}
                          width={32}
                          height={32}
                          alt=""
                        />
                      </FlexRow>
                    )}
                  </FlexRow>
                  <Headline>{title}</Headline>
                  <Body>{body}</Body>
                </Card>
              );
            },
          )}
        </Slider>
      </SliderContainer>
    </Section>
  );
};

export default Features;
