import styled from "styled-components";
import device from "src/constants/media";
import { colors } from "src/theme";
import { TextContainer } from "src/theme/typography";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-inline: auto;
  margin-top: 80px;
  max-width: 1096px;
  position: relative;

  @media ${device.tablet} {
    margin-top: 160px;
  }
`;

export const IllustrationWrapper = styled.div`
  position: absolute;
  left: -200px;
  bottom: -32px;
  z-index: 1;

  @media ${device.laptop} {
    left: -18vw;
  }
  @media ${device.laptopL} {
    left: -25vw;
  }
`;

export const SliderContainer = styled.div`
  .slick-list {
    overflow: visible;
    margin-inline: -20px;
    @media ${device.tabletS} {
      margin-inline: 0;
    }
    @media ${device.laptopS} {
      margin-left: -80px;
      margin-right: -68px;
    }
  }

  .slick-track {
    display: flex;
    gap: 20px;

    @media ${device.tablet} {
      gap: 40px;
    }
  }

  .slick-slide {
    height: auto;
  }
`;
export const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.main};
  grid-column: span 6;
  margin: 0 24px 24px;
  margin-top: 48px;
  @media ${device.tablet} {
    margin: 0;
    margin-top: 96px;
  }
  @media ${device.laptop} {
    margin-top: 111px;
  }
`;
export const TitleContainer = styled(TextContainer)`
  padding-inline: 24px;
  text-align: center;
`;
export const IconBlock = styled.div`
  background-color: ${colors.mainLight};
  border-radius: 8px;
  display: flex;
  min-height: 56px;
  place-content: center;
  min-width: 56px;
`;
export const Card = styled(TextContainer)<{ as?: string; href?: string }>`
  background-color: ${colors.white};
  padding: 20px 24px;
  grid-column: span 6;
  border-radius: 8px;
  transition: all 400ms ease-in-out;
  min-height: 340px;

  @media ${device.tablet} {
    min-height: 280px;
  }

  #read-more {
    opacity: 0;
    transition: all 400ms ease-in-out;
  }

  ${({ href }) =>
    href &&
    `:hover {
      background-color: ${colors.greyVeryLight};
      box-shadow: 0px 8px 64px -32px rgba(0,0,0,0.4);
      transform: scale(1.01);

      #read-more {
        opacity: 1;
      }
    }`}
`;
